
import { Action } from "vuex-class";
import { Component, Mixins } from "vue-property-decorator";
import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormYesNo, FormCheckbox } from "@/components/forms";
import { formatErrors } from "@/utils/formatters";
import { HasVgeModal } from "@/mixins/vge/has-vge-modal";
import { asbestosTypeOptions } from "@/utils/select-options";
import { DocumentType } from "@/store/modules/document.store";
import { AsbestosType } from "@/store/modules/partner.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        CustomerInvoicePart: () => import("@/components/modals/vge/parts/customer-invoice.vue"),
        FormBase,
        FormInput,
        FormDate,
        FormTextarea,
        FormSelect,
        FormError,
        FormCheckbox,
        FormYesNo,
        FormSubmit,
    },
})
export default class AddVgeAsbestosModal extends Mixins(HasVgeModal) {
    @Action("vastgoedexperts/createAsbestos") createAsbestos!: (payload: any) => Promise<void>;

    asbestosTypeOptions = asbestosTypeOptions;

    modalType = "show-vge-add-asbestos";

    sqMetersOptions = [
        { label: "0m² t.e.m. 100m²", value: 0 },
        { label: "101m² t.e.m. 250m²", value: 1 },
        { label: "251m² t.e.m. 400m²", value: 2 },
        { label: "401m² t.e.m. 600m²", value: 3 },
        { label: ">600m²", value: 4 },
    ];

    documents = [DocumentType.vgeAsbestosAssignmentAgreement, DocumentType.vgeAsbestosDocumentationConstructionPlans, DocumentType.vgeAsbestosDocumentationOther, DocumentType.vgeAsbestosDocumentationPostIntervention];

    prefillLivableArea() {
        const livable_area: number | null = this.property?.characteristics?.livable_area;

        if (!livable_area) {
            return;
        }

        if (livable_area >= 0 && livable_area < 101) {
            this.payload.square_meters = 0;
        } else if (livable_area >= 101 && livable_area < 251) {
            this.payload.square_meters = 1;
        } else if (livable_area >= 251 && livable_area < 401) {
            this.payload.square_meters = 2;
        } else if (livable_area >= 401 && livable_area < 601) {
            this.payload.square_meters = 3;
        } else if (livable_area >= 601) {
            this.payload.square_meters = 4;
        }
    }

    get hasMissingDocuments() {
        return !this.matchedUploadedDocuments.includes(DocumentType.vgeAsbestosAssignmentAgreement);
    }

    async submit(form: FormClass) {
        try {
            this.payload.subtype = AsbestosType.Asbestos;

            this.sanitizePayload();

            await this.createAsbestos(this.payload);

            this.handleClose();

            if (this.callback && this.partner) {
                this.callback(this.partner);
            }

            this.$toast.open({ message: "Keuring aangevraagd", type: "success" });
        } catch (e) {
            this.errorResponse = formatErrors(e);
        } finally {
            form.reset();
        }
    }
}
